/**
 * @todo refactoring
 */
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import Image from '../components/Images'
import ProductAction from '../actions/ProductAction'
import HowItWorks from '../components/HowItWorks'
import ShopButton from '../components/ShopButton'
import PreFooter from '../components/PreFooter'
import HowItWorksStyle from '../styles/components/howitworks.module.scss'
import PrintablesStyle from '../styles/components/printableTests.module.scss'
import CollaborationsStyle from '../styles/pages/collaborations.module.scss'
import Style from '../styles/components/test.module.scss'
import ProductStyle from '../styles/components/products.module.scss'
import FooterStyle from '../styles/components/footer.module.scss'

const Landing = ({
  pageContext,
  data: {
    prismicPageLanding: {data},
    howItWorks,
    staticText,
    footerBackground
  }
}) => {
  const staticPrismicTexts = staticText.edges[0].node.data.texts;
  const _t = (str) => staticPrismicTexts[staticPrismicTexts.findIndex(item => item.key_text === str)].text.text;
  const stories = data.stories.filter((item) => item.story.document[0].data.test_image.url !== null).map((item) => {
    return {
      projectId: item.story.document[0].data.project_id,
      name: item.story.document[0].data.title.text,
      image: item.story.document[0].data.test_image.url,
      alt_image: item.story.document[0].data.test_alt_attribute,
      type: 'printable',
      isBook: item.story.document[0].data.physical_book,
      isPrintable: item.story.document[0].data.printable,
      step_1: item.story.document[0].data.step_1,
      step_2: item.story.document[0].data.step_2,
      step_3: item.story.document[0].data.step_3,
      inAppPurchase: item.story.document[0].data.in_app_purchase,
      popupBackground: item.story.document[0].data.popup_background,
      pageTracking: `landing-${pageContext.uid}`
    }
  });
  
  let storiesRows = [];
  let steps = [];
  storiesRows.length = Math.ceil(stories.length / 3);
  for (let i = 0; i < stories.length; ++i) {
    let key = Math.floor(i/3);
    if (typeof storiesRows[key] === 'undefined') {
      storiesRows[key] = [];
    }
    storiesRows[key].push(stories[i]);
  }

  if (storiesRows.length === 1) {
    steps = [
      storiesRows[0][0].step_1,
      storiesRows[0][0].step_2,
      storiesRows[0][0].step_3,
    ]
  }
  
  const Tests = (props) => {
    return (
      <div className={`${PrintablesStyle.containers} ${Style.testsContainers}`}>
        <img
          src={props.image}
          className="twic"
          alt={props.alt_image}
          style={{ cursor: 'pointer' }}
          onClick={() => document.getElementById(`product-item-${props.projectId}`).click()}
        />
        <h3 style={{fontSize: 17}}>{props.name}</h3>
        <ProductAction 
          page="listing" 
          product={props} 
          context={props.context} 
          _t={props._t}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <Layout 
        context={pageContext} 
        pageTitle={data.seo__meta_title_.text}
        _t={staticPrismicTexts}
        hideMenu={data.naked_mode}
      >
        <SEO 
          pageTitle={data.seo__meta_title_.text} 
          pageDescription={data.seo__meta_description_.text} 
          context={pageContext} 
          ogImage={data.banner.url}
        />
        <div className="page">
          <div className={`${CollaborationsStyle.hero} ${CollaborationsStyle.noMargin}`}>
            <Image src={data.banner.url} alt={data.banner.alt} />
          </div>

          <section 
            className={`${PrintablesStyle.section} ${!data.naked_mode ? PrintablesStyle.sectionPadding : ''} ${Style.hero} ${data.naked_mode ? 'no-padding custom' : ''}`} 
            style={{
              backgroundColor: "white",
              paddingBottom: `${data.naked_mode ? 0 : 50}`
            }}
          >
            <div className="container">
              {
                !data.naked_mode ? (
                  <React.Fragment>
                    <div className="page-head no-padding no-custom-style">
                      <h2>{data.page_title.text}</h2>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: data.page_subtitle.html }}></div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="page-head no-padding no-custom-style landing-custom">
                      <h3 style={{ color: '#00ABE7', fontSize: 26 }}>{data.page_title.text}</h3>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: data.page_subtitle.html }}></div>

                    <div className="page-head no-padding no-custom-style landing-custom" style={{marginTop: 70}}>
                      <h3 style={{ color: '#00ABE7', fontSize: 26 }}>
                        {data.overwrite_print_instructions_title.text}
                      </h3>
                    </div>
                    <div>{data.overwrite_print_instructions_text.text}</div>
                  </React.Fragment>
                )
              }
            </div>
            
            {
              (data.naked_mode && data.custom_image.url) && (
                <div className="container">
                  <div className={PrintablesStyle.customImage}>
                    <Image src={data.custom_image.url} alt={data.custom_image.alt} />
                  </div>
                  <div className={PrintablesStyle.storyContent}>
                    <h3 style={{ fontSize: 17 }}>
                      {data.stories[0].story.document[0].data.title.text}
                    </h3>
                    <a 
                      href={data.story_direct_download.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className={`${ProductStyle.buy} button default square btn btn_default`}
                    >
                      <i className={`w-icons icon-download`}></i>
                    </a>
                  </div>
                </div>
              )
            }

            {
              data.naked_mode && (
                <React.Fragment>
                  <div className="page-head no-padding no-custom-style landing-custom" style={{marginTop: 90}}>
                    <h3 style={{ color: '#00ABE7', fontSize: 26 }}>
                      {data.overwrite_how_does_it_work_title.text}
                    </h3>
                  </div>
                  <div style={{ marginBottom: 20}}>
                    {data.overwrite_how_does_it_work_text.text}
                  </div>
                  {
                    data.custom_contents_striped.map((item, index) => {
                      return (
                        <div className={HowItWorksStyle.customStepsContainer} key={`custom-step-${index}`}>
                          <div>
                            <Image transparent src={item.image.url} alt={item.image.alt} />
                          </div>
                          <div>
                            <div dangerouslySetInnerHTML={{ __html: item.content.html }}></div>
                          </div>
                        </div>
                      )
                    })
                  }
                </React.Fragment>
              )
            }

            { (storiesRows.length && !data.naked_mode) > 0 && storiesRows.map((row, rowIndex) => {
              return (
                <div className={`${PrintablesStyle.tests} ${Style.testsContainer}`} key={`testsRow-${rowIndex}`}>
                  {row.map((item, index) => {
                    return (
                      <Tests 
                        {...item} 
                        key={`test-${rowIndex}-${index}`} 
                        context={pageContext}
                        _t={staticPrismicTexts}
                      />
                    )
                  })}
                </div>
              )
            })}
          </section>
          {
            (storiesRows.length && !data.naked_mode) > 0 && (
              <section className={HowItWorksStyle.section} style={{ backgroundColor: "#f3f3f3" }}>
                <div className={Style.subtitle} style={{marginBottom: 50}}>
                  <p>Comment ça marche ?</p>
                  <p>Suivez <strong>les 3 étapes</strong> pour <strong>Wakatooner</strong></p>
                </div>
                <HowItWorks {...howItWorks} {...{ homepage: true }} steps={steps} animate={false} collapse={true} />
              </section>
            )
          }

          {
            !data.naked_mode && (
              <section className={Style.shopSection} style={{ backgroundColor: "white" }}>
                <div className={Style.subtitle} dangerouslySetInnerHTML={{ __html: data.page_footer.html }}></div>
                {
                  _t && <ShopButton shopUrl={pageContext.shop_urls['fr']} label={_t('La boutique Wakatoon')} />
                }
              </section>
            )
          }
        </div>
        {
          !data.naked_mode ? (
            <PreFooter locale={pageContext.locale} />
          ) : (
            <div>
              <section
                className={`${FooterStyle.sectionPreFooterCustom}`}
                style={
                  {
                    padding: 0,
                    minHeight: 'auto',
                    backgroundPosition: 'bottom',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${footerBackground.edges[0].node.publicURL})`
                  }
                }
              >
                <div><Image transparent src={data.custom_footer_image.url} alt={data.custom_footer_image.alt} /></div>
                <div dangerouslySetInnerHTML={{ __html: data.custom_footer_text.html }}></div>
              </section>
            </div>
          )
        }
      </Layout>
    </React.Fragment>
  );
}


export default Landing;

export const pageQuery = graphql`
  query landingPage($uid: String!, $locale: String!) {
    prismicPageLanding(uid: { eq: $uid }, lang: { eq: $locale }) {
      lang
      data {
        seo__meta_title_ {
          text
        }
        seo__meta_description_ {
          text
        }
        banner {
          url
          alt
        }
        page_title {
          text
        }
        page_subtitle {
          html
        }
        naked_mode
        story_direct_download {
          url
        }
        custom_contents_striped {
          content {
            html
          }
          image {
            alt
            url
          }
        }
        custom_footer_image {
          #alt
          url
        }
        custom_footer_text {
          html
        }
        custom_image {
          alt
          url
        }
        overwrite_print_instructions_title {
          text
        }
        overwrite_print_instructions_text {
          text
        }
        overwrite_how_does_it_work_title {
          text
        }
        overwrite_how_does_it_work_text {
          text
        }
        stories {
          story {
            document {
              data {
                project_id
                title {
                  text
                }
                test_image {
                  alt
                  url
                }
                test_alt_attribute
                type
                physical_book
                printable
                in_app_purchase
                popup_background {
                  url
                  alt
                }
                step_1 {
                  url
                  alt
                }
                step_2 {
                  url
                  alt
                }
                step_3 {
                  url
                  alt
                }
              }
            }
          }
        }
        page_footer {
          html
        }
        shop_button
      }
    }
    howItWorks: allPrismicSectionHowitworks(filter: { lang: { eq: $locale} }) {
      edges { ...howItWorks }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
    footerBackground: allFile(filter: {relativePath: {eq: "pre-footer-background-flat.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;